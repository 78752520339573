<template>
    <div>
        <div class="fluid-container footer">
            <div class="footer_layout">
                <b-container fluid>
                    <b-row style="" class="mb-3">
                        <b-col sm="5" style="padding-top:5px;">
                            <strong>{{$t('footer.header')}}</strong><br>
                            <sub>{{$t('footer.address')}}</sub>
                        </b-col>
                        <b-col sm="5" style="padding-top:5px;">
                            <strong>{{$t('footer.contact')}}</strong>
                            <div class="contact">
                                <p>
                                    <a href = "tel:+662298216974">
                                        <i class="fa fa-phone-alt"></i> 02298-2169-74
                                    </a>
                                </p>
                                <p>
                                    <a href = "mailto: Aqnis.web@gmail.com">
                                        <i class="fa fa-envelope"></i> Aqnis.web@gmail.com
                                    </a>
                                </p>
                                <p>
                                    <a href="https://www.facebook.com/PCD.go.th" target="_blank">
                                        <i class="fab fa-facebook-square"></i> {{$t('footer.facebook')}}
                                    </a>
                                </p>
                            </div>
                        </b-col>
                        <b-col sm="2" style="padding-top:5px;">
                            <strong>{{$t('footer.hotline')}}</strong><br>
                            <a style="font-size:max(3.5vw, 22px);color:white;" href="tel://1650">1650</a>
                            <!-- <strong style="font-size:max(3.5vw, 22px);" helf="tel://1650">1650</strong> -->
                        </b-col>
                    </b-row>
                    <b-row style="padding-top:25px;" class="mb-3">
                        <b-col sm="6">
                            <strong>{{$t('footer.link_hd')}}</strong><br>
                            <b-link href="http://www.noise4thai.net/web/index.php" target="_blank" style="color:white;">{{$t('footer.noise_web')}}</b-link>
                            <br>
                            <b-link href="https://erg4thai.com/web/#/" target="_blank" style="color:white;">{{$t('footer.erg4thai_web')}}</b-link>
                        </b-col>
                        <b-col sm="6">
                            <b-row>
                                <b-col sm="12">
                                    <div class="link_popup" @click="openPopup()">
                                            {{ $i18n.locale === 'th' ? 'ขั้นตอนการขอข้อมูลคุณภาพอากาศและเสียง' : 'Procedure for requesting air and sound quality information' }}
                                    </div>
                                </b-col>
                                <b-col sm="12">
                                    <div class="link_popup" @click="openPDF()">
                                        {{ $i18n.locale === 'th' ? 'คู่มือการปฎิบัตงานและการให้บริการข้อมูลคุณภาพอากาศ' : 'Practical Guide Jobs and Air Quality Information Services' }}
                                    </div>
                                </b-col>
                                <b-col sm="12">
                                    <div class="old_link">
                                        <!-- ชมเว็ปไซต์เวอร์ชั่นเก่า คลิก!
                                        <a href="http://air4thai.pcd.go.th/webV2/" target="_blank">
                                            <img src="@/assets/old_link.png" alt="apple" style="padding-bottom:20px;" width="60px" >
                                        </a> -->
                                        <div style="height: 15px;"></div>
                                        <p>จำนวนผู้เยี่ยมชม (ครั้ง)</p>
                                        <a href="https://www.hitwebcounter.com/" target="_blank">
                                        <img src="https://hitwebcounter.com/counter/counter.php?page=10077572&style=0006&nbdigits=5&type=page&initCount=0" title="Counter Widget" Alt="Visit counter For Websites"   border="0" /></a>
                                    </div>
                                </b-col>
                                <b-col sm="12">
                                    <div class="app_icon">
                                        <a href="https://apps.apple.com/us/app/air4thai/id594762042" target="_blank">
                                            <img src="@/assets/Apple_icon.png" alt="apple" style="padding:10px;" width="120px" >
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.kathistudio.air4thaimobile" target="_blank">
                                            <img src="@/assets/GooglePlay.png" alt="google" style="padding:10px;" width="120px" >
                                        </a>
                                        <a href="http://www.google.com" target="_blank">
                                            <img src="@/assets/Huawei_icon.png" alt="huawei" style="padding:10px;" width="120px" >
                                        </a>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <hr style="border-top: 1px solid white;"/>
                    <strong style="padding-left:0px;">Copyright © 2013-2022 {{$t('footer.facebook')}}</strong>
                </b-container>
            </div>
        </div>

        <b-modal id="modal-image" size="xl" hide-footer>
            <b-container>
                <div style="text-align: center;padding:5px;">
                    <b-img src="@/assets/popup_01.jpg" fluid></b-img>
                    <!-- <b-img src="@assets/manual_2566.pdf" fluid></b-img> -->
                </div>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    components : {
    
    },
    data () {
        return {

        }
    },
    methods : {
        openPopup () {
            this.$bvModal.show('modal-image')
            // window.open("http://air4thai.pcd.go.th/webV3/pdf/manual_2566.pdf", "_blank");
        },
        openPDF() {
            // this.$bvModal.show('modal-image')
            window.open("http://air4thai.pcd.go.th/webV3/pdf/manual_2566.pdf", "_blank");
        }
    }
}
</script>

<style lang="scss" scoped>
.old_link{
    text-align: center;
}
    .fluid-container.footer{
        background:  #2E2E2E;
        // height: 70px;
    }
    .fluid-container.footer > *:last-child {
        margin-bottom:0px;
        
        // text-align: left;
        // padding:0px;
        // font-size: 12px;
        // font-size: 1.2vw;
        // margin-top:10px;
    }
    .footer_layout{
        text-align: left;
        padding: 20px;
        color: #ffffff;
        font-size: max(1.2vw, 12px);
    }
    .contact {
        line-height:10px;
        margin-top:10px;
    }
    .contact a{
        color: #fff;
    }
    .app_icon{
        text-align: center;
        margin-top:10px;
        // position: relative;
    }
    .app_icon a{
        // margin-top: 0;
        // padding-top: 0;
        // margin-right: 30px;
    }
    .app_icon a img{
        // margin-top: 0;
        // padding-top: 0;
    }
    .link_popup {
        text-align: center;
        margin-top:10px;
    }
    .link_popup:hover {
        cursor: pointer;
        // color: #016FB7;
        text-decoration: underline;
    }
</style>