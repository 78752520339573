<template>
    <div>
        <Header/>
        <div class=" v_content">
        <div style="padding:15px;">
            <div class="justify-content-md-center" style="max-width: 150vh;margin: auto;">
                <div style="text-align:left;margin-top:20px;">
                <!-- <h5><strong>{{$t('header_region')}}</strong></h5> -->
                <b-row>
                    <b-col cols="12" sm="12" md="6">
                        <div class="list_select">
                            <!-- <label style="margin-top:15px;">{{ $i18n.locale === 'th' ? 'ภูมิภาค' : 'Region' }} </label> -->
                            <label>{{$t('list')}} | </label>
                            <select v-model="select_region" class="region_select" @change="change_selectRegion()">
                                <option :value="null"> {{ $i18n.locale === 'th' ? 'ทั่วประเทศ' : 'Nationwide' }}</option>
                                <option v-for="region, index in regions" :key="index" :value="region.regionID">
                                    {{ $i18n.locale === 'th' ? region.nameTH : region.nameEN }}
                                </option>
                            </select>
                            <label style="margin-left:5px;"> | </label>
                            <select v-model="station_select" class="area_select">
                                <option :value="null"> {{ $i18n.locale === 'th' ? 'ทั้งหมด' : 'ALL' }}</option>
                                <option v-for="station, index in stations" :key="index" :value="station.stationID">
                                    {{ $i18n.locale === 'th' ? station.areaTH : station.areaEN }}
                                </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col cols="12" sm="12" md="6">
                        <b-input-group class="search_input" size='sm'>
                            <template #prepend>
                                <b-input-group-text><i class="fas fa-search"></i></b-input-group-text>
                            </template>
                            <b-form-input v-model="search_input" :placeholder="$t('search')"></b-form-input>
                            <b-input-group-append v-if="search_input != ''" >
                                <b-button variant="outline-light" style="border-color:#D5D5D5;color:gray" @click="searchClear()">
                                    <i class="fas fa-times-circle"></i>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                </div>
                <b-row style="margin-top:10px;">
                    <b-col sm="12" md="12" lg="6">
                        <div style="text-align:left;margin-bottom:-15px;">
                            <small>{{drawDateData()}}</small>
                        </div>
                        <div class="map_layout">
                        <l-map
                        style="height: 100%; width: 100%;border-radius: 2px;text-align:left;"
                        :zoom="zoom"
                        :center="center"
                        name="map"
                        id="map"
                        ref="map"
                        @update:zoom="zoomUpdate"
                        >
                            <l-control-layers position="topright"  ref="layersControl"></l-control-layers>
                            <l-tile-layer
                            v-for="tileProvider in tileProviders"
                            :key="tileProvider.name"
                            :name="tileProvider.name"
                            :visible="tileProvider.visible"
                            :url="tileProvider.url"
                            :attribution="tileProvider.attribution"
                            layer-type="base"/>

                            <l-marker v-for="marker, index in filter_list_map" :lat-lng="[marker.lat,marker.long]" :key="index">
                                <l-icon
                                :icon-size="dynamicSize"
                                :icon-anchor="dynamicAnchor"
                                :popupAnchor="dynamicPopupAnchor"
                                class-name="someExtraClass">
                                    <!-- <img :src="icon.Url" :height="dynamicSize[1]"> -->
                                    <!-- <img src="@/assets/markers/pin_g.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id <= 0"> -->
                                    <img src="@/assets/markers/pin_0.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 1">
                                    <img src="@/assets/markers/pin_1.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 2">
                                    <img src="@/assets/markers/pin_2.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 3">
                                    <img src="@/assets/markers/pin_3.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 4">
                                    <img src="@/assets/markers/pin_4.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 5">
                                    <img src="@/assets/markers/pin_5.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 6">
                                    <img src="@/assets/markers/pin_6.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 7">
                                    <img src="@/assets/markers/pin_7.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 8">
                                    <img src="@/assets/markers/pin_8.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 9">
                                    <img src="@/assets/markers/pin_9.png" :height="dynamicSize[1]" v-if="marker.AQILast[mapshowSelected].color_id == 10">
                                    <strong class="centered" :style="dynamicFont" style="padding-top:2px;color:black;" v-if="marker.AQILast[mapshowSelected].color_id > 0">
                                        {{ aqlVal(marker.AQILast[mapshowSelected],mapshowSelected) }}
                                    </strong>
                                </l-icon>
                            </l-marker>

                            <l-control position="topright" >
                                <b-form-select v-model="mapshowSelected" :options="mapshowOptions"></b-form-select>
                            </l-control>
                        </l-map>
                        </div>
                    </b-col>
                    <b-col sm="12" md="12" lg="6" style="text-align:left;">
                        <div>
                            <div class="dropdown_dl">
                                <b-dropdown variant="link" toggle-class="text-decoration-none" dropleft no-caret v-if="downloadDropDown != null && downloadDropDown.length > 0">
                                    <template #button-content>
                                        <i class="fas fa-download" style="font-size:28px;line-height:0;"></i>
                                        <div style="padding:0px;margin:-5px;">
                                            <strong style="font-size:10px;padding:0;margin:0;line-height:0;">{{$t('download_bt')}}</strong>
                                        </div>
                                    </template>
                                    <b-dropdown-item :href="data.link" target="_blank" v-for="data,index in downloadDropDown" :key="index">
                                        {{ $i18n.locale === 'th' ? data.titleTH : data.titleEN }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <strong style="color:#016FB7;">
                                {{$t('airquality_area')}} {{ regionsName() }}
                            </strong><br>
                            <small>{{$t('airquality')}} {{ minmaxDescription }}</small>
                            <div v-if='station_alert != ""'>
                                <div v-html="station_alert"></div>
                            </div>
                        </div>
                        <!-- <b-row style="padding-top:20px;" class="buttom_bar">
                            <b-col cols="6" sm="4" md="4" lg="6" xl="4" class="mb-4" v-for="region, index in regions" :key="index">
                                <b-card class="card_region" :class="class_selectRegion(region.regionID)" @click="click_selectRegion(region.regionID)">
                                    <img src="@/assets/icon/BKK.png" alt="logo" class="card_image" v-if="region.regionID == '1'">
                                    <img src="@/assets/icon/N.png" alt="logo" class="card_image" v-if="region.regionID == '2'">
                                    <img src="@/assets/icon/NE.png" alt="logo" class="card_image" v-if="region.regionID == '5'">
                                    <img src="@/assets/icon/W.png" alt="logo" class="card_image" v-if="region.regionID == '4'">
                                    <img src="@/assets/icon/C.png" alt="logo" class="card_image" v-if="region.regionID == '7'">
                                    <img src="@/assets/icon/E.png" alt="logo" class="card_image" v-if="region.regionID == '3'">
                                    <img src="@/assets/icon/S.png" alt="logo" class="card_image" v-if="region.regionID == '6'">
                                    <b-card-text>
                                        <strong style="font-size:22px;">{{ $i18n.locale === 'th' ? region.nameTH : region.nameEN }}</strong>
                                    </b-card-text>
                                </b-card>
                            </b-col>
                        </b-row> -->
                    </b-col>
                </b-row>
                <div style="text-align:left;margin-top:20px;">
                    <strong>{{ regionsName() }}</strong>
                    <b-card style=" margin-top:15px;cursor: pointer;" v-for="station, index in filter_list" :key="index" @click="select_station(station.stationID)">
                        <b-row class="repoer_list_big">
                            <b-col md="2">
                                <div class="aqiFace">
                                    <img src="@/assets/face/icon-nodata.png" height="100" v-if="station.AQILast.AQI.color_id <= 0"/>
                                    <img src="@/assets/face/icon-excellent.png" height="100" v-if="station.AQILast.AQI.color_id == '1'"/>
                                    <img src="@/assets/face/icon-satisfactory.png" height="100" v-if="station.AQILast.AQI.color_id == '2'"/>
                                    <img src="@/assets/face/icon-moderate.png" height="100" v-if="station.AQILast.AQI.color_id == '3'"/>
                                    <img src="@/assets/face/icon-unhealthy.png" height="100" v-if="station.AQILast.AQI.color_id == '4'"/>
                                    <img src="@/assets/face/icon-very unhealthy.png" height="100" v-if="station.AQILast.AQI.color_id == '5'"/>
                                    <p class="aqiFace-Text" v-if="station.AQILast.AQI.aqi != '-1'" :class="station.AQILast.AQI.color_id <= 0 ? 'aqi_color_off' : ''">
                                        <span className="ver-space">
                                            <strong style="fontSize:24px">
                                                {{station.AQILast.AQI.aqi}}
                                            </strong>
                                        </span><br>
                                        <small style="fontSize:14px;">AQI</small>
                                    </p>
                                </div>
                                <div style="borderRadius:15px;padding:2px;text-align: center;">
                                    <strong style="fontSize:14px;padding:2px;" v-if="$i18n.locale === 'th'">
                                        {{type_text[station.AQILast.AQI.color_id - 1]}}
                                    </strong>
                                    <strong style="fontSize:14px;padding:2px;" v-else>
                                        {{type_text_e[station.AQILast.AQI.color_id - 1]}}
                                    </strong>
                                </div>
                            </b-col>
                            <b-col md="4" style="padding-left:20px;">
                                <label style="color: 'black';fontSize: '16px';" :class="station.AQILast.AQI.color_id <= 0 ? 'aqi_color_off' : ''">
                                    <label style="color:gray;">{{station.stationID}}</label> <strong>{{$i18n.locale === 'th' ? station.areaTH : station.areaEN}}</strong>
                                </label><br>
                                <label style="color: 'grey'; fontSize: '12px';" :class="station.AQILast.AQI.color_id <= 0 ? 'aqi_color_off' : ''">
                                    {{convertDate(station.AQILast.date + " " + station.AQILast.time)}}
                                </label>
                            </b-col>
                            <b-col md="6">
                                <b-row style="text-align: center;">
                                    <b-col v-for="param, index in checkParamsDraw(station)" :key="index">
                                        <div v-if="param != ''">
                                            <div v-html="titleParam[param]" :class="station.AQILast.AQI.color_id <= 0 ? 'aqi_color_off' : ''"></div>
                                            <!-- <div v-html="param"></div> -->
                                            <strong style="fontSize:30px"  :style="{color : indexColor(station.AQILast[param].color_id)}" v-if="station.AQILast.AQI.color_id > 0">
                                                {{station.AQILast[param].aqi === "-1" ? "-" : station.AQILast[param].aqi === "-999" ? "-" : param === "AQI" ? station.AQILast[param].aqi : station.AQILast[param].value}}
                                            </strong>
                                            <strong style="fontSize:30px" class="aqi_color_off" v-else>
                                                {{station.AQILast[param].aqi === "-1" ? "-" : station.AQILast[param].aqi === "-999" ? "-" : param === "AQI" ? station.AQILast[param].aqi : station.AQILast[param].value}}
                                            </strong>
                                            <div v-html="unit[param]" :class="station.AQILast.AQI.color_id <= 0 ? 'aqi_color_off' : ''"></div>
                                            <!-- <div style="margin-top:-10px;"><small style="font-size:10px;color:gray;">{{params_sub[index]}}</small></div> -->
                                            <div style="margin-top:-10px;"><small style="font-size:10px;color:gray;">{{ checkParamsSubDraw(param) }}</small></div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="repoer_list_small" style="padding:0px;margin-top:-10px;">
                            <b-col cols="2" sm="2" style="padding:0px;">
                                <div>
                                    <div class="circle" :style="{background : indexColor(station.AQILast.AQI.color_id)}">
                                        <p class="aqiFace-Text-small" :class="station.AQILast.AQI.color_id <= 0 ? 'aqi_color_off' : ''">
                                            <span>
                                                <strong>
                                                    {{station.AQILast.AQI.aqi == '-1' ? '-' : station.AQILast.AQI.aqi}}
                                                </strong>
                                            </span><br>
                                            <small style="font-size:10px;">AQI</small>
                                        </p>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="10" sm="10" style="padding:0px;">
                                <label class="station-small">
                                    <label style="color:gray;">{{station.stationID}}</label> <strong>{{$i18n.locale === 'th' ? station.areaTH : station.areaEN}}</strong>
                                </label>
                            </b-col>
                        </b-row>
                        <b-row class="repoer_list_small">
                            <!-- <b-col cols="2" sm="2" style="padding:0px;margin-top:10px;">
                                <div>
                                    <div class="circle" :style="{background : indexColor(station.AQILast.AQI.color_id)}">
                                        <p class="aqiFace-Text-small">
                                            <span>
                                                <strong>
                                                    {{station.AQILast.AQI.aqi}}
                                                </strong>
                                            </span><br>
                                            <small>AQI</small>
                                        </p>
                                    </div>
                                </div>
                            </b-col> -->
                            <!-- <b-col cols="6" sm="6">
                                <label class="station-small">
                                    {{station.stationID}} <strong>{{$i18n.locale === 'th' ? station.areaTH : station.areaEN}}</strong>
                                </label><br>
                            </b-col> -->
                            <b-col cols="12" sm="12" style="padding:0px;margin-top:10px;">
                                <b-row style="text-align: center;">
            
                                    <!-- <div v-for="param, index in params" :key="index" > -->
                                    <!-- <b-col style="padding-top:0px;" v-if="station.AQILast[param].aqi != '-1' && station.AQILast[param].aqi != '-999'"> -->
                                    <b-col style="padding-top:0px;" v-for="param, index in checkParamsDraw(station)" :key="index" >
                                        <div class="data-small" v-if="param != ''">
                                            <div v-html="titleParam[param]" class="title-small" :class="station.AQILast.AQI.color_id <= 0 ? 'aqi_color_off' : ''"></div>
                                            <strong style="font-size:14px;margin:0px;padding:0px;"  :style="{color : indexColor(station.AQILast[param].color_id)}" v-if="station.AQILast.AQI.color_id > 0">
                                                {{station.AQILast[param].aqi === "-1" ? "-" : station.AQILast[param].aqi === "-999" ? "-" : param === "AQI" ? station.AQILast[param].aqi : station.AQILast[param].value}}
                                            </strong>
                                            <strong style="font-size:14px;margin:0px;padding:0px;" class="aqi_color_off" v-else>
                                                {{station.AQILast[param].aqi === "-1" ? "-" : station.AQILast[param].aqi === "-999" ? "-" : param === "AQI" ? station.AQILast[param].aqi : station.AQILast[param].value}}
                                            </strong>
                                            <div v-html="unit[param]" class="title-small" style="margin-top:8px;" :class="station.AQILast.AQI.color_id <= 0 ? 'aqi_color_off' : ''"></div>
                                            <!-- <div style="margin-top:-2px;margin-bottom:15px;"><small style="font-size:6px;color:gray;">{{params_sub[index]}}</small></div> -->
                                            <div style="margin-top:-2px;margin-bottom:15px;"><small style="font-size:6px;color:gray;">{{ checkParamsSubDraw(param) }}</small></div>
                                        </div>
                                    </b-col>
                                    <!-- </div> -->
                                </b-row>
                            </b-col>
                        </b-row>
                        <label class="date-small repoer_list_small">
                            {{convertDate(station.AQILast.date + " " + station.AQILast.time)}}
                        </label>
                        <!-- <label class="station-small repoer_list_small">
                            <b-row>
                                <b-col cols="2" sm="2">

                                </b-col>
                                <b-col cols="10" sm="10">
                                    <label style="color:gray;">{{station.stationID}}</label> <strong>{{$i18n.locale === 'th' ? station.areaTH : station.areaEN}}</strong>
                                </b-col>
                            </b-row>
                        </label> -->
                        <label class="click-small repoer_list_small">
                            {{$i18n.locale === 'th' ? 'กดเพื่อดูข้อมูลเพิ่มเติม >' : 'Press for more information >'}}
                        </label>
                    </b-card>
                    <div style="text-align:left;margin-top:10px;color:gray;font-size:14px;">
                        <span>- : ไม่มีข้อมูล , bxx : ขึ้นต้นด้วย b สถานีตรวจวัดของ กทม. , xxt : ลงท้ายด้วย t สถานีตรวจวัดของ คพ.</span>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import moment from 'moment'
import axios from 'axios'
import main_function from '../../mixins/main_funxtion'
import L from 'leaflet';
import { LMap ,LTileLayer ,LMarker  ,LControlLayers ,LIcon, LControl} from 'vue2-leaflet';

import 'leaflet-velocity/dist/leaflet-velocity.css';
import 'leaflet-velocity/dist/leaflet-velocity';

export default {
    name : 'Report',
    mixins: [main_function],
    components : {
        Header,
        Footer,
        LMap,
        LTileLayer,
        LMarker,
        LControlLayers,
        LIcon,
        LControl
    },
    data () {
        return {
            download_list:{
                0:[ // ทั่วประเทศ
                    {
                        titleTH: "รายงานประจำวัน เวลา 07:00 น.",
                        titleEN: "Daily report time 07:00 AM",
                        link: "http://air4thai.com/report/PMMetroReport_TEST_07.jpg"
                    },
                    {
                        titleTH: "รายงานประจำวัน เวลา 12:00 น.",
                        titleEN: "Daily report time 12:00 AM",
                        link: "http://air4thai.com/report/PMMetroReport_TEST_12.jpg"
                    },
                    {
                        titleTH: "รายงานประจำวัน เวลา 17:00 น.",
                        titleEN: "Daily report time 17:00 AM",
                        link: "http://air4thai.com/report/PMMetroReport_TEST_17.jpg"
                    },
                    // {
                    //     titleTH: "ตาราง PM2.5 เวลา 07:00 น.",
                    //     titleEN: "PM2.5 report time 07:00 AM",
                    //     link: "http://air4thai.pcd.go.th/report/HazeTableReportM_V2003_" + this.getReportFileName(0) + "_7.pdf"
                    // }
                ],
                1:[ // กทม
                    {
                        titleTH: "รายงานประจำวัน เวลา 07:00 น.",
                        titleEN: "Daily report time 07:00 AM",
                        link: "http://air4thai.com/report/PMMetroReport_" + this.getReportFileName(0) + "_7.jpg"
                    },
                    {
                        titleTH: "รายงานประจำวัน เวลา 12.00 น.",
                        titleEN: "Daily report time 12:00 AM",
                        link: "http://air4thai.com/report/PMMetroReport_" + this.getReportFileName(0) + "_12.jpg"
                    },
                    {
                        titleTH: "รายงานประจำวัน เวลา 17.00 น.",
                        titleEN: "Daily report time 17:00 AM",
                        link: "http://air4thai.com/report/PMMetroReport_" + this.getReportFileName(0) + "_17.jpg"
                    },
                    {
                        titleTH: "ตาราง PM2.5 เวลา 07:00 น.",
                        titleEN: "PM2.5 report time 07:00 AM",
                        link: "http://air4thai.pcd.go.th/report/HazeTableReportM_V2003_" + this.getReportFileName(0) + "_7.pdf"
                    },
                    {
                        titleTH: "คาดการณ์ฝุ่นละออง",
                        titleEN: "Forecast",
                        link: "http://air4thai.pcd.go.th/UpdateV2/forecastImage/forecast_img_bkk.png"
                    },
                    // {
                    //     titleTH: "แถลงการณ์ ศกพ.",
                    //     titleEN: "Anouncement",
                    //     link: "http://air4thai.pcd.go.th/report/reportCAMP.pdf"
                    // }

                ],
                2:[ // ภาคเหนือ
                    // {
                    //     titleTH: this.convertDateOnly(this.getReportFileName(0)),
                    //     titleEN: this.convertDateOnly(this.getReportFileName(0)),
                    //     link: "http://air4thai.com/report/AirReportN_V2003_"+this.getReportFileName(0)+".pdf"
                    // },
                    // {
                    //     titleTH: this.convertDateOnly(this.getReportFileName(1)),
                    //     titleEN: this.convertDateOnly(this.getReportFileName(1)),
                    //     link: "http://air4thai.com/report/AirReportN_V2003_"+this.getReportFileName(1)+".pdf"
                    // },
                    // {
                    //     titleTH: this.convertDateOnly(this.getReportFileName(2)),
                    //     titleEN: this.convertDateOnly(this.getReportFileName(2)),
                    //     link: "http://air4thai.com/report/AirReportN_V2003_"+this.getReportFileName(2)+".pdf"
                    // },
                    // {
                    //     titleTH: "รายงานประจำวัน เวลา 07:00 น.",
                    //     titleEN: "Daily report time 07:00 AM",
                    //     link: "http://air4thai.com/report/PM25NorthReportV2003_" + this.getReportFileName(0) + "_07.png"
                    // },
                    {
                        titleTH: "สถานการณ์คุณภาพอากาศในพื้นที่ภาคเหนือ",
                        titleEN: "Expected dust in the northern region",
                        link: "http://air4thai.com/report/AirReportN_V2003_" + this.getReportFileName(0) + ".pdf"
                    },
                    // {
                    //     titleTH: "คาดการฝุ่นละอองภาคเหนือตอนบน",
                    //     titleEN: "Expected dust in the upper northern region",
                    //     link: "http://air4thai.com/UpdateV2/forecastImage/forecast_img_north_top.png"
                    // },
                    // {
                    //     titleTH: "คาดการฝุ่นละอองภาคเหนือตอนล่าง",
                    //     titleEN: "Expected dust in the lower northern region",
                    //     link: "http://air4thai.com/UpdateV2/forecastImage/forecast_img_north_bottom.png"
                    // }
                    {
                        titleTH: "คาดการณ์ฝุ่นละออง",
                        titleEN: "Forecast",
                        link: "http://air4thai.com/UpdateV2/forecastImage/forecast_img_north.png"
                    },
                    
                    // {
                    //     titleTH: "ตาราง PM2.5 เวลา 09:00 น.",
                    //     titleEN: "PM2.5 report time 09:00 AM",
                    //     link: "http://air4thai.pcd.go.th/report/HazeTableReportN_V2003_" + this.getReportFileName(0) + "_9.pdf"
                    // }
                    
                ],
                5:[
                    // {
                    //     titleTH: "รายงานประจำวัน เวลา 07:00 น.",
                    //     titleEN: "Daily report time 07:00 AM",
                    //     // link: "http://air4thai.pcd.go.th/report/PMNEReport.png"
                    //     link: "http://air4thai.com/report/PM25NorthEastReport_" + this.getReportFileName(0) + "_07.jpg"
                    // },
                    {
                        titleTH: "คาดการณ์ฝุ่นละออง",
                        titleEN: "Forecast",
                        link: "http://air4thai.com/UpdateV2/forecastImage/forecast_img_esan.png"
                    },
                    
                ],
                7:[ // ภาคกลางและตะวันตก
                    // {
                    //     titleTH: "รายงานประจำวัน เวลา 07:00 น.",
                    //     titleEN: "Daily report time 07:00 AM",
                    //     // link: "http://air4thai.pcd.go.th/report/PMWestReport.png"
                    //     link: "http://air4thai.com/report/PM25CentralReport_" + this.getReportFileName(0) + "_07.jpg"
                    // },
                    // {
                    //     titleTH: "ตาราง PM2.5 เวลา 09:00 น.",
                    //     titleEN: "PM2.5 report time 09:00 AM",
                    //     link: "http://air4thai.pcd.go.th/report/HazeTableReportC_V2003_" + this.getReportFileName(0) + "_9.pdf"
                    // }
                ],
                3:[
                    // {
                    //     titleTH: "รายงานประจำวัน เวลา 07:00 น.",
                    //     titleEN: "Daily report time 07:00 AM",
                    //     // link: "http://air4thai.pcd.go.th/report/PMEastReport.png"
                    //     link: "http://air4thai.com/report/PM25EastReport_" + this.getReportFileName(0) + "_07.jpg"
                    // },
                    // {
                    //     titleTH: "ตาราง PM2.5 เวลา 09:00 น.",
                    //     titleEN: "PM2.5 report time 09:00 AM",
                    //     link: "http://air4thai.pcd.go.th/report/HazeTableReportE_V2003_" + this.getReportFileName(0) + "_9.pdf"
                    // }
                    {
                        titleTH: "คาดการณ์ฝุ่นละออง",
                        titleEN: "Forecast",
                        link: "http://air4thai.com/UpdateV2/forecastImage/forecast_img_east.png"
                    },
                ],
                6:[ // ภาคใต้
                    // {
                    //     titleTH: "สถานการณ์คุณภาพอากาศในพื้นที่ภาคใต้",
                    //     titleEN: "Expected dust in the southern region",
                    //     link: "http://air4thai.com/report/AirReportS_V2003_" + this.getReportFileName(0) + ".pdf"
                    // },
                    // {
                    //     titleTH: "รายงานประจำวัน เวลา 07:00 น.",
                    //     titleEN: "Daily report time 07:00 AM",
                    //     link: "http://air4thai.com/report/PM25SouthReportV2006_" + this.getReportFileName(0) + "_07.png"
                    // },
                    // {
                    //     titleTH: "ตาราง PM2.5 เวลา 09:00 น.",
                    //     titleEN: "PM2.5 report time 09:00 AM",
                    //     link: "http://air4thai.pcd.go.th/report/HazeTableReportS_V2003_" + this.getReportFileName(0) + ".pdf"
                    // },
                    // {
                    //     titleTH: "สถานการณ์หมอกควัน " + this.getReportThaiDate(0),
                    //     titleEN: "Smog situation " + this.getReportThaiDate(0),
                    //     link: "http://air4thai.com/report/AirReportS_V2003_" + this.getReportFileName(0) + ".pdf"
                    // },
                    // {
                    //     titleTH: "สถานการณ์หมอกควัน " + this.getReportThaiDate(1),
                    //     titleEN: "Smog situation " + this.getReportThaiDate(1),
                    //     link: "http://air4thai.com/report/AirReportS_V2003_" + this.getReportFileName(1) + ".pdf"
                    // },
                    // {
                    //     titleTH: "สถานการณ์หมอกควัน " + this.getReportThaiDate(2),
                    //     titleEN: "Smog situation " + this.getReportThaiDate(2),
                    //     link: "http://air4thai.com/report/AirReportS_V2003_" + this.getReportFileName(2) + ".pdf"
                    // },
                    {
                        titleTH: "คาดการณ์ฝุ่นละออง",
                        titleEN: "Forecast",
                        link: "http://air4thai.com/UpdateV2/forecastImage/forecast_img_south.png"
                    },
                ],
                8:[
                    {
                        titleTH: "รายงานประจำวัน เวลา 07:00 น.",
                        titleEN: "Daily report time 07:00 AM",
                        link: "http://air4thai.com/report/PMSaraBuriReport_" + this.getReportFileName(0) + ".jpg"
                    },
                    // {
                    //     titleTH: "ตาราง PM2.5 เวลา 09:00 น.",
                    //     titleEN: "PM2.5 report time 09:00 AM",
                    //     link: "http://air4thai.pcd.go.th/report/HazeTableReportNPL_V2003_" + this.getReportFileName(0) + "_9.pdf"
                    // }
                ]
            },
            select_region:0,
            station_select:null,
            regions:[],
            stations:[],
            search_input:'',
            type_text : ["คุณภาพดีมาก", "คุณภาพดี", "ปานกลาง", "เริ่มมีผลกระทบต่อสุขภาพ", "มีผลกระทบต่อสุขภาพ", "-"],
            type_text_e : ["Very Good", "Good", "Moderate", "Start to affect", "Affect health", "-"],
            params : ["PM25", "PM10", "O3", "NO2", "CO", "SO2"],
            params_sub: ["Avg 24Hr","Avg 24Hr","Avg 8Hr","","Avg 8Hr",""],
            // titleParam : [
            //     "<label>PM<sub>2.5</sub></label>",
            //     "<label>PM<sub>10</sub></label>",
            //     "<label>O<sub>3</sub></label>",
            //     "<label>NO<sub>2</sub></label>",
            //     "<label>CO</label>",
            //     "<label>SO<sub>2</sub></label>"
            // ],
            // unit : [
            //     "<label>ug/m<sup>3</sup></label>",
            //     "<label>ug/m<sup>3</sup></label>",
            //     "<label>ppb</label>", 
            //     "<label>ppb</label>", 
            //     "<label>ppm</label>", 
            //     "<label>ppb</label>"
            // ],
            titleParam : {
                PM25:"<label>PM<sub>2.5</sub></label>",
                PM10:"<label>PM<sub>10</sub></label>",
                O3:"<label>O<sub>3</sub></label>",
                NO2:"<label>NO<sub>2</sub></label>",
                CO:"<label>CO</label>",
                SO2:"<label>SO<sub>2</sub></label>"
            },
            unit : {
                PM25:"<label>ug/m<sup>3</sup></label>",
                PM10:"<label>ug/m<sup>3</sup></label>",
                O3:"<label>ppb</label>", 
                NO2:"<label>ppb</label>", 
                CO:"<label>ppm</label>", 
                SO2:"<label>ppb</label>"
            },
            regions_map:{
                zoom:[5,10,7,8,0,7,7,8,11,12],
                lat:[13.751375,13.802725,17.976527,13.128158,0,16.135539,9.046196,13.829686,14.7,14.7100539],
                lng:[100.492619,100.546488,99.512939,101.437268,0,103.013306,100.138379,100.09082,100.85,100.8304413]
            },
            // Map 
            mapshowSelected:'AQI',
            mapshowOptions:[
                { value: 'AQI', text: 'AQI' },
                { value: 'PM25', text: 'PM2.5' },
                { value: 'PM10', text: 'PM10' },
                { value: 'CO', text: 'CO' },
                { value: 'O3', text: 'O3' },
                { value: 'NO2', text: 'NO2' },
                { value: 'SO2', text: 'SO2' }
            ],
            icon: {
                Url: '',
                Size: [56, 68],
                Anchor: [28, 68]
            },
            lmap:null,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 5.4,
            currentZoom: 5.4,
            center: [13.4612352,100.3307459],
            tileProviders: [
                {
                name: 'OpenStreetMap',
                visible: false,
                attribution:
                    '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                },
                {
                name: 'Grey Canvas',
                visible: true,
                url: "http://{s}.sm.mapstack.stamen.com/(toner-lite,$fff[difference],$fff[@23],$fff[hsl-saturation@20])/{z}/{x}/{y}.png",
                attribution:
                    'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                },
                {
                name: 'World Map',
                visible: true,
                url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                attribution:
                    '',
                },
                {
                    name:'cyclosm',
                    visible: true,
                    url:'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
                }
            ]
        }
    },
    methods : {
        checkParamsDraw(_stations){
            // console.log('checkParamsDraw ',_stations)
            var rParam = ["","","","","",""]
            var count = 0
            for(var a=0;a<this.params.length;a++){
                // if(_stations.AQILast[this.params[a]].aqi != '-1' && _stations.AQILast[this.params[a]].aqi != '-999'){
               if(parseFloat(_stations.AQILast[this.params[a]].aqi) >= 0){
                    rParam[count] = this.params[a]
                    count++
               }
            }
            return rParam
        },
        checkParamsSubDraw(param_type) {
            if (param_type == "PM25" || param_type == "PM10")
                return "Avg 24Hr"
            else if (param_type == "O3" || param_type == "CO")
                return "Avg 8Hr"

            return ""
        },
        searchClear(){
            this.search_input = ''
        },
        convertDate(date_time){
            var convertDate
            if(this.$i18n.locale === 'th'){
                convertDate = moment(date_time).add(543, 'year').format('DD MMM YYYY HH:mm')
                convertDate = convertDate + " น."
            }else
                convertDate = moment(date_time).format('DD MMM YYYY HH:mm')

            return convertDate
        },
        convertDateOnly(date_time){
            var convertDate
            if(this.$i18n.locale === 'th'){
                convertDate = moment(date_time).add(543, 'year').format('DD MMM YYYY')
                // convertDate = convertDate + ""
            }else
                convertDate = moment(date_time).format('DD MMM YYYY')

            return convertDate
        },
        drawDateData(){
            if(this.stations.length <= 0){
                return '-';
            }

            if(this.$i18n.locale === 'th')
                moment.locale('th')
            else
                moment.locale('en')
            
            return this.checkLastDataTime()
        },
        checkLastDataTime () {
            var date_now = this.stations[0]['AQILast']['date'] + " " + this.stations[0]['AQILast']['time']
            var convertDate =  moment(date_now)

            for(var a=1; a<this.stations.length; a++){
                var date_time = this.stations[a]['AQILast']['date'] + " " + this.stations[a]['AQILast']['time']
                var curentDate =  moment(date_time)

                if(curentDate.diff(convertDate) > 0){
                    date_now = this.stations[a]['AQILast']['date'] + " " + this.stations[a]['AQILast']['time']
                    convertDate = curentDate
                }
            }

            if(this.$i18n.locale === 'th'){
                convertDate = moment(convertDate).add(543, 'year').format('DD MMM YYYY เวลา HH:mm')
                convertDate = "ประจำวัน" + moment(date_now).format('dddd') + " ที่ " + convertDate + " น."
            }else
                convertDate = "Data updated " + moment(convertDate).format('DD dddd MMM YYYY HH:mm')
            return convertDate
        },
        getReportThaiDate(decreaseDay) {
            let date = new Date();
            date.setDate(date.getDate() - decreaseDay);
            //console.log(date);
            let day = date.getDate();
            let month = date.getMonth() + 1;
            // let year = date.getFullYear() + 543;
            let thaiMonth = "";
            switch (month) {
                case 1:
                    thaiMonth = "ม.ค.";
                    break;
                case 2:
                    thaiMonth = "ก.พ.";
                    break;
                case 3:
                    thaiMonth = "มี.ค.";
                    break;
                case 4:
                    thaiMonth = "เม.ย.";
                    break;
                case 5:
                    thaiMonth = "พ.ค.";
                    break;
                case 6:
                    thaiMonth = "มิ.ย.";
                    break;
                case 7:
                    thaiMonth = "ก.ค.";
                    break;
                case 8:
                    thaiMonth = "ส.ค.";
                    break;
                case 9:
                    thaiMonth = "ก.ย.";
                    break;
                case 10:
                    thaiMonth = "ตุลาคม";
                    break;
                case 11:
                    thaiMonth = "พ.ย.";
                    break;
                case 12:
                    thaiMonth = "ธ.ค.";
                    break;
                default:
                    break;
            }
            return this.$i18n.locale === 'th' ?  `${day} ${thaiMonth}` : `${day}/${month}`
        },
        getReportFileName(decreaseDay) {
            let date = new Date();
            date.setDate(date.getDate() - decreaseDay);
           
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            var return_date = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
            //console.log('return_date',return_date);
            return return_date
        },
        zoomUpdate(zoom){
            //console.log('zoom',zoom)
            this.currentZoom = zoom
        },
        select_station(sid){
            // console.log(sid)
            this.$router.push({ name: 'StationDetail', params: { sid: sid } })
        },
        class_selectRegion(index){
            if(index == this.select_region){
                return 'card_region_active'
            }

            return ''
        },
        movetoRegionPos(index){
            //console.log('index',index)
            //this.zoom = this.regions_map['zoom'][index]
            //this.zoomUpdate(this.regions_map['zoom'][index])
            this.$nextTick(() => {
                this.center = [this.regions_map.lat[index],this.regions_map.lng[index]]
                this.lmap.panTo(new L.LatLng(this.center[0], this.center[1]));
                if(index == 0)
                    this.zoom = 5.4
                else if(index == 1)
                    this.zoom = 9
                else
                    this.zoom = 6
                
            })
        },
        getSelectAllRegion(){
            axios.get('http://air4thai.com/forweb/getAQI_JSON.php',{}).then((response) => {
                this.stations = response.data.stations
                this.setStationList()
                this.movetoRegionPos(0)
            }).catch(err => {
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: err,
                    icon: 'error'
                })
            })
        },
        change_selectRegion(){
            // console.log('get data : ',this.select_region)
            if(this.select_region == null){
                this.getSelectAllRegion()
            }else{
                axios.get('http://air4thai.com/forweb/getAQI_JSON.php?region=' + this.select_region,{}).then((response) => {

                    //console.log("get aqi" , response.data.stations)

                    this.stations = response.data.stations
                    this.setStationList()
                    this.movetoRegionPos(this.select_region)
                }).catch(err => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        title: 'Error!',
                        text: err,
                        icon: 'error'
                    })
                })
            }
        },
        click_selectRegion(index){
            // console.log("select",index)

            if(this.select_region != index){
                if(index == null){
                    this.getSelectAllRegion()
                }else{
                    axios.get('http://air4thai.com/forweb/getAQI_JSON.php?region=' + index,{}).then((response) => {
                        // console.log('click_selectRegion', response.data.stations)
                        this.stations = response.data.stations
                        this.setStationList()
                        this.movetoRegionPos(index)
                    }).catch(err => {
                        this.$swal({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            title: 'Error!',
                            text: err,
                            icon: 'error'
                        })
                    })
                }
            }

            this.select_region = index
        },
        setStationList(){
            // console.log('stations',datas)
            this.station_select = null
        },
        regionsName(){
            var s_index = 0;
            if(this.select_region == null)
                return this.$i18n.locale === 'th' ? 'ทั่วประเทศ' : 'Nationwide'

            for(var a=0;a<this.regions.length;a++){
                if(this.regions[a].regionID == this.select_region){
                    s_index = a;
                }
            }
            if(this.regions.length == 0){
                return ''
            }
            return this.$i18n.locale === 'th' ? this.regions[s_index].nameTH : this.regions[s_index].nameEN
        },
        aqlVal(val,param){
            var AQIval = '-';//= val > -1 ? val : "-";
            AQIval = val.aqi === "-1" ? "-" : val.aqi === "-999" ? "-" : param === "AQI" ? val.aqi : val.value
            return AQIval
        },
        getMinMaxValueFromParam(minType,param,stationList){
            var lowest = -1;
            var highest = -1;
            var tmp = -1
            for (var i = stationList.length - 1; i >= 0; i--) {
                tmp = stationList[i].AQILast[param].value;
                if (tmp > -1){
                    if (tmp < lowest || lowest == -1) lowest = tmp;
                    if (tmp > highest || highest == -1) {
                        highest = tmp;
                    }
                }
            }
            // stationList.filter(station => station.AQILast[param].value > "-1").map((station)=>{
            //     tmpMin = Number(station.AQILast[param].value);
            //     if (tmpMin < lowest){
            //         lowest = tmpMin;
            //     }
            // });
            // stationList.map((station)=>{
            //     tmpMax = Number(station.AQILast[param].value);
            //     if (tmpMax > highest) {
            //         highest = tmpMax;
            //     }
            // });
            return minType ? lowest : highest;
        }
    },
    created () {
        moment.locale('th')
        // console.log('param',this.$route.params.rid)
        var list_url = 'http://air4thai.com/forweb/getList_JSON.php'
        // var list_url = 'http://air4thai.com/forappV2/getList_JSON.php'
        axios.get(list_url,{}).then((response) => {
            // console.log('getList_JSON',response.data)
            this.regions = []//response.data.regions
            for(var b=0;b<response.data.regions.length;b++){
                if(response.data.regions[b].regionID != '8'){
                    this.regions.push(response.data.regions[b]);
                }
            }
            var haveit = false;
            for(var a=0;a<this.regions.length;a++){
                if(this.regions[a].regionID == this.$route.params.rid){
                    haveit = true;
                    break;
                }
            }
            if(haveit)
                this.click_selectRegion(this.$route.params.rid)
            else
                this.click_selectRegion(null)
        }).catch(err => {
            this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                title: 'Error!',
                text: err,
                icon: 'error'
            })
        })
    },
    mounted() {
        this.lmap = this.$refs['map'].mapObject
    },
    computed: {
        downloadDropDown () {
            var returnDownload = []
            if(this.select_region == null)
                returnDownload = this.download_list["0"]
            else
                returnDownload = this.download_list["" + this.select_region]

            return returnDownload
        },
        minmaxDescription () {
            var description = ""
            var lowest = 0
            var highest = 0
            var tmp = 0
            for (var i = this.stations.length - 1; i >= 0; i--) {
                tmp = this.stations[i].AQILast.AQI.color_id;
                if (tmp > 0){
                    if (tmp < lowest || lowest == 0) lowest = tmp;
                    if (tmp > highest || highest == 0) {
                        highest = tmp;
                    }
                }
            }
            var minDescription = this.descriptionAQIIndexFromColor(lowest)
            var maxDescription = this.descriptionAQIIndexFromColor(highest)
            if (lowest == highest) description += minDescription
            else description += minDescription + " " + this.$t('to') + " " + maxDescription

            return description
        },
        station_alert () {
            var param = {
                "PM25": '<Text>ฝุ่นละออง ขนาดไม่เกิน 2.5 ไมครอน (PM<sub>2.5</sub>)</Text>',
                "PM10": '<Text>ฝุ่นละออง ขนาดไม่เกิน 10 ไมครอน (PM<sub>10</sub>)</Text>',
                "O3": '<Text>ก๊าซโอโซน (O<sub>3</sub>)</Text>',
                "CO": '<Text>ก๊าซคาร์บอนมอนออกไซด์ (CO)</Text>',
                "NO2": '<Text>ก๊าซไนโตรเจนไดออกไซด์ (NO<sub>2</sub>)</Text>',
                "SO2": '<Text>ก๊าซซัลเฟอร์ไดออกไซด์ (SO<sub>2</sub>)</Text>'
            }
            // var unit = {
            //     'PM25': '<Text>ไมโครกรัมต่อลูกบาศก์เมตร (&#181;g/m<sup>3</sup>)</Text>',
            //     'PM10': '<Text>ไมโครกรัมต่อลูกบาศก์เมตร (&#181;g/m<sup>3</sup>)</Text>',
            //     'O3': '<Text>ส่วนในพันล้านส่วน (ppb) ค่ามากที่สุดในรอบ 24 ชั่วโมง</Text>',
            //     'SO2': '<Text>ส่วนในพันล้านส่วน (ppb)</Text>',
            //     'NO2': '<Text>ส่วนในพันล้านส่วน (ppb)</Text>',//ค่ามากที่สุดในรอบ 24 ชั่วโมง
            //     'CO': '<Text>ส่วนในล้านส่วน (ppm)</Text>',
            // }
            var lowest = 0
            var highest = 0
            var tmp = 0
            var station_l = []
            var return_text = ""
            for (var i = this.stations.length - 1; i >= 0; i--) {
                tmp = this.stations[i].AQILast.AQI.color_id;
                if (tmp > 0){
                    if (tmp < lowest || lowest == 0) lowest = tmp;
                    if (tmp > highest || highest == 0) {
                        highest = tmp;
                    }
                }
                if (tmp > 3) {
                    station_l.push(this.stations[i])
                }
            }

            if(highest > 3){
                return_text += "<div>"
                return_text += "<label style='color:red;'>สารมลพิษทางอากาศที่ตรวจพบเกินมาตรฐานได้แก่</label>"

                //console.log(lowest + "," + highest);

                // Check Same Param //
                var param_station = []
                for(var a2=0;a2<station_l.length;a2++){
                    var have_it =false
                    var index = 0
                    for(var b=0;b<param_station.length;b++){
                        if(param_station[b].param == station_l[a2].AQILast.AQI.param){
                            have_it = true
                            index = b
                            break
                        }
                    }
                    if(have_it){
                        param_station[index].stationID.push(station_l[a2].areaTH)
                    }else{
                        param_station.push({
                            param:station_l[a2].AQILast.AQI.param,
                            stationID:[station_l[a2].areaTH]
                        })
                    }
                }

                //console.log(param_station)
                for(var c=0;c<param_station.length;c++){
                    return_text += 
                    "<label style='font-size: 14px'> <strong>" + param[param_station[c].param] +  "</strong> เกินมาตรฐานที่บริเวณ <br>"
                    for(var d=0;d<param_station[c].stationID.length;d++){
                        return_text += param_station[c].stationID[d] 
                        if(d < param_station[c].stationID.length -1)
                        return_text += ","
                    }
                    return_text += "</label>"
                }
                // for(var a=0;a<station_l.length;a++){
                //     // return_text += "<label style='font-size: 14px'> <strong>" + param[station_l[a].AQILast.AQI.param] +  "</strong>";


                //     if(lowest == highest){
                //         return_text += 
                //         "<label style='font-size: 14px'> <strong>" + param[station_l[a].AQILast.AQI.param] + "</strong> ตรวจพบค่า " + this.getMinMaxValueFromParam(false,station_l[a].AQILast.AQI.param,this.stations) + " " + unit[station_l[a].AQILast.AQI.param] + "<br /> เกินมาตรฐานที่บริเวณ " + station_l[a].areaTH + "</label>"
                //     }else{
                //         return_text += 
                //         "<label style='font-size: 14px'> <strong>" + param[station_l[a].AQILast.AQI.param] + "</strong> ตรวจพบค่าระหว่าง " + this.getMinMaxValueFromParam(true,station_l[a].AQILast.AQI.param,this.stations) + "-" + this.getMinMaxValueFromParam(false,station_l[a].AQILast.AQI.param,this.stations) + " " + unit[station_l[a].AQILast.AQI.param] + "<br /> เกินมาตรฐานที่บริเวณ " + station_l[a].areaTH + "</label>"
                //     }
                // }

                return_text += "</div>"
            }

            return return_text
        },
        dynamicSize () {
            var factor = Math.pow(this.currentZoom / 11.5, 1);
            const newX = Math.round(this.icon.Size[0] * factor);
            const newY = Math.round(this.icon.Size[1] * factor);
            return [newX, newY];
            // return [this.icon.Size[0], this.icon.Size[1]];
        },
        dynamicAnchor () {
            var factor = Math.pow(this.currentZoom / 11.5, 1);
            const newX = Math.round(this.icon.Size[0] * factor);
            const newY = Math.round(this.icon.Size[1] * factor);
            return [newX / 2, newY * 0.8];
            // return [this.icon.Size[0] / 2, this.icon.Size[1] * 0.8];
        },
        dynamicPopupAnchor () {
            var factor = Math.pow(this.currentZoom / 11.5, 1);
            const newY = Math.round(this.icon.Size[1] * factor);
            return [0 , -(newY * 0.6)];
            // return [0, -(this.icon.Size[1]* 0.6)];
        },
        dynamicFont () {
            var factor = Math.pow(this.currentZoom / 11.5, 1);
            const newSize = Math.round(28 * factor);
            return 'font-size:' + newSize + 'px'
        },
        station_list () {
            if(this.station_select == null){
                return this.stations
            }
            
            return this.stations.filter(d => d.stationID === this.station_select)
        },
        filter_list(){
            var station_list_temp = []
            var have_list = this.station_list.length
            for(var a=0;a<have_list;a++){
                // if(this.station_list[a].AQILast[this.mapshowSelected].color_id > 0)
                    station_list_temp.push(this.station_list[a])
            }

            if(this.search_input == '')
                return station_list_temp

            var return_station = []

            return_station = station_list_temp.filter((d) => {
                return this.search_input.toLowerCase().split(' ').every(v => d.stationID.toLowerCase().includes(v));
            });
            if(return_station.length == 0){
                if(this.$i18n.locale === 'th'){
                    return_station = station_list_temp.filter((d) => {
                        return this.search_input.toLowerCase().split(' ').every(v => d.areaTH.toLowerCase().includes(v));
                    });
                }else{
                    return_station = station_list_temp.filter((d) => {
                        return this.search_input.toLowerCase().split(' ').every(v => d.areaEN.toLowerCase().includes(v));
                    });
                }
            }

            // console.log('filter_list',return_station)

            return return_station
        },
        filter_list_map(){
            var station_list_temp = []
            var have_list = this.station_list.length
            for(var a=0;a<have_list;a++){
                if(this.station_list[a].AQILast[this.mapshowSelected].color_id > 0)
                    station_list_temp.push(this.station_list[a])
            }

            if(this.search_input == '')
                return station_list_temp

            var return_station = []

            return_station = station_list_temp.filter((d) => {
                return this.search_input.toLowerCase().split(' ').every(v => d.stationID.toLowerCase().includes(v));
            });
            if(return_station.length == 0){
                if(this.$i18n.locale === 'th'){
                    return_station = station_list_temp.filter((d) => {
                        return this.search_input.toLowerCase().split(' ').every(v => d.areaTH.toLowerCase().includes(v));
                    });
                }else{
                    return_station = station_list_temp.filter((d) => {
                        return this.search_input.toLowerCase().split(' ').every(v => d.areaEN.toLowerCase().includes(v));
                    });
                }
            }

            // console.log('filter_list',return_station)

            return return_station
        }
    }
}
</script>

<style lang="scss" scoped>
.map_layout{
    height: 400px;
    margin-top:20px;
    border-style: solid;
    border-color: rgb(80, 79, 79);
    border-width:5px;
    border-radius: 10px;
    box-shadow: 2px 5px 10px rgba(68, 68, 68, 0.6);
}
.card_image{
    height: 50px;
}
.card_region_title{
    text-align: left;
    height:110%;
    border-color: white;
    border-width: 0px;
    padding: 0px;
    margin: 0px;
}
// .card_region{
//     height:100%;
//     background: #016FB7;
//     color:white;
//     cursor: pointer;
// }
.card_region{
    height:100%;
    background: #ffffff;
    color:rgb(0, 0, 0);
    cursor: pointer;
}
.card_region_active{
    height:100%;
    background: #016FB7;
    color:white;
    -webkit-filter: invert(0%); /* Safari/Chrome */
    filter: invert(0%);
}
.card_region img{
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}
.card_region_active img{
    -webkit-filter: invert(0%); /* Safari/Chrome */
    filter: invert(0%);
}
.aqiFace {
  position: relative;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aqi_color_off {
    color:rgb(146, 146, 146);
}
.aqiFace-Text {
  margin: auto;
  position: absolute;
  top: 45%;
  line-height: 16px;
  text-align: center;
}
.aqiFace-Text Text{
  margin-top: 0;
}
.dropdown_dl{
    // position: absolute;
    // right: 20px;
    // top: 0;
    // align-items: right;
    text-align: right;
}
.list_select{
    margin-top:20px;
    color: #016FB7;
}
.region_select{
    color: #016FB7;
    border-width: 0px;
}
.area_select{
    color: #016FB7;
    border-width: 0px;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.search_input{
    // width: 120px;
    margin-top:15px;
    // padding-left:15px;
    // padding-right:15px;
}
@media only screen and (min-width : 992px) {
    .repoer_list_small{
        display: none;
    }
}
@media only screen and (max-width : 992px) {
    .repoer_list_big{
        display: none;
    }
}
@media only screen and (max-width : 420px) {
    .repoer_list_small2{
        display: none;
    }
}
@media only screen and (max-width : 720px) {
    .repoer_list_small1{
        display: none;
    }
}
.circle{
    margin: 0px;
    padding: 0px;
    position: absolute;
    // background: #20c1fc8e;
    width: 35px;
    height: 35px;
    left: 50%;
    top: 45%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    // transform: translateY(50%);
    // -ms-transform: translateY(50%);
    border-radius: 50%;
    z-index: 90;
}
.aqiFace-Text-small {
    position: relative;
    z-index: 99;
    line-height: 10px;
    text-align: center;
    margin-top:8px;
}
.aqiFace-Text-small strong{
    font-size: 14px;
}
.aqiFace-Text-small small{
    font-size: 12px;
}
.station-small{
    // position: absolute;
    // left: 0px;
    // top: 0px;
    padding: 0px;
    margin: 0px;
    color: black;
    font-size: 11px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap; 
    // height: 20px;
    // width: 70%;
}
.date-small{
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 5px;
    margin: 0px;
    color: grey; 
    font-size: 10px;
}
.click-small{
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;
    margin: 0px;
    color: grey; 
    font-size: 10px;
}
.data-small{
    line-height: 0px;
}
.title-small{
    font-size: 10px;
    padding:0px;
    margin:0px;
    line-height: 12px;
}
</style>